body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  text-align: center;
}

.app__header {
  padding-top:5px;
  box-shadow: 0 3px 10px #a17a69;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.app__header h1 {
  margin-left: 15px;
}

.app__header__links {
  display: flex;
  margin-right: 10px;
  flex-wrap: wrap;
  max-width: 35vw;
}

.app__header__links a {
  margin: 5px;
  text-decoration: none;
  color: black;
}

.app__header__links a:clicked {
  color: black;
}

.p__container {
  width: 90vw;
  max-width: 500px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 3px 10px #a17a69;
  padding: 5px;
  padding-bottom: 10px;
}

.p__container p {
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 5px;
}

.app__skills {
  width: 90vw;
  max-width: 500px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 3px 10px #a17a69;
  padding: 5px;
}

.app__skills__container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
}

.app__skills__card {
  margin: 15px;
  padding: 10px;
  border: 1px lightgray;
  border-radius: 10px;
  box-shadow: 0 3px 10px #a17a69;
}

.app__skills__card img{
  height: 50px;
}

.app__projects {
  width: 90vw;
  max-width: 500px;
  margin: auto;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0 3px 10px #a17a69;
  padding: 5px;
  text-align: center;
}

.app__projects__card img {
  width: 90%;
  margin: 15px;
  padding: 0px;
  border: 1px solid whitesmoke;
  border-radius: 10px;
  box-shadow: 0 3px 10px #a17a69;
}

.projects__link {
  margin-left: 5px;
  margin-right: 5px;
}

.socials {
  width: 90vw;
  max-width: 500px;
  margin: auto;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 3px 10px #a17a69;
  padding: 5px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}

.socials__link img {
  width: 50px;
}
 h3 {
  text-align: center;
 }

 .contact__form {
  width: 90vw;
  max-width: 500px;
  margin: auto;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 3px 10px #a17a69;
  padding: 5px;
  text-align: center;
 }

 .contact__form form {
  display: flex;
  flex-direction: column;
 }

 .contact__form input {
  width: 300px;
 }

 .contact__form textarea {
  resize: none;
 }

 .success {
  text-align: center;
 }

 .app__header a {
  text-decoration: none;
  color: black
 }

 .app__header a:clicked {
  text-decoration: none;
  color: black;
 }